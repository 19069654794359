import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client"

import fetch from "cross-fetch"

export const client = new ApolloClient({
  link: new HttpLink({
    uri: process.env.API_URL + "/graphql" || "http://localhost:1337/graphql",
    fetch,
  }),
  cache: new InMemoryCache(),
})
